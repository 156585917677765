








import Vue from "vue";
import { mapState } from "vuex";
import { dispatch } from "@/store";

export default Vue.extend({
  name: "MainLayout",
  data() {
    return {
      loading: true
    };
  },
  computed: {
    ...mapState("app", {
      language: "language"
    })
  },
  watch: {
    "$route.params.language"(language) {
      if (language) {
        if (language !== this.language) {
          dispatch.appSetLanguage(language);
          sessionStorage.setItem("language", language);
        }
      } else {
        this.$router.replace({
          path: "/" + this.language + this.$route.path,
          query: this.$route.query
        });
      }
    }
  },
  mounted() {
    let language = this.$route.params.language;
    if (language) {
      dispatch.appSetLanguage(language);
      sessionStorage.setItem("language", language);
      this.loading = false;
    } else if (sessionStorage.getItem("language")) {
      this.$router.replace({
        path: "/" + sessionStorage.getItem("language") + this.$route.path,
        query: this.$route.query
      });
    } else {
      language = "cn";
      dispatch
        .appCheckIP()
        .then(res => {
          if (res && res.iso_code) {
            switch (res.iso_code) {
              case "CN":
              case "HK":
              case "MO":
              case "TW":
                language = "cn";
                break;
              default:
                language = "en";
                break;
            }
          }
          this.$router.replace({
            path: "/" + language + this.$route.path,
            query: this.$route.query
          });
        })
        .catch(() => {
          this.$router.replace({
            path: "/" + language + this.$route.path,
            query: this.$route.query
          });
        });
    }
  }
});
